import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import SyllablesEvaluation from "../../../../../components/syllables-evaluation"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"

const Page = () => {
  const { getAnswer, getTask, getStatsForTaskWithDict } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })
  const taskProps = {
    courseId: "reiseerinnerung",
    chapterId: "02-dichterwerkstatt",
    taskId: "silben",
  }
  const task = getTask(taskProps)
  const answer = getAnswer(taskProps)
  const statistics = getStatsForTaskWithDict(taskProps)

  const RenderAnswer = ({ id, children }) => {
    return (
      <SyllablesEvaluation
        answer={answer?.[id]}
        solution={task.solution[id]}
        key={`answer_${id}`}
      >
        {children}
      </SyllablesEvaluation>
    )
  }

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/reiseerinnerung/02-dichterwerkstatt/silben/zusammenfassung"
        />
      }
    >
      <Seo title="Hölderlin und die Jahreszeiten" />
      <Stack>
        <FeedbackHeaderProgress
          correct={statistics.correct}
          total={statistics.correct + statistics.incorrect + statistics.missed}
        />
        <Stack space="6">
          <RenderAnswer id="q-01">Der Nord-ost we-het,</RenderAnswer>
          <RenderAnswer id="q-02">Der lieb-ste un-ter den Win-den</RenderAnswer>
          <RenderAnswer id="q-03">Mir, weil er feu-ri-gen Geist</RenderAnswer>
          <RenderAnswer id="q-04">
            Und gu-te Fahrt ver-heiß-et den Schif-fern.
          </RenderAnswer>
        </Stack>
        <Stack space="6">
          <RenderAnswer id="q-05">Geh a-ber nun und grü-ße</RenderAnswer>
          <RenderAnswer id="q-06">Die schö-ne Ga-ronne,</RenderAnswer>
          <RenderAnswer id="q-07">Und die Gär-ten von Bour-deaux</RenderAnswer>
          <RenderAnswer id="q-08">Dort, wo am schar-fen U-fer</RenderAnswer>
        </Stack>
        <Stack space="6">
          <RenderAnswer id="q-09">
            Hin-geh-et der Steg und in den Strom
          </RenderAnswer>
          <RenderAnswer id="q-10">
            Tief fällt der Bach, da-rü-ber a-ber
          </RenderAnswer>
          <RenderAnswer id="q-11">Hin-schau-et ein e-del Paar</RenderAnswer>
          <RenderAnswer id="q-12">
            Von Ei-chen und Sil-ber-pap-peln;
          </RenderAnswer>
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
